<template>
  <div class="unit-list">
    <div
      class="action-area"
      v-if="hasAction('edit-team-evaluation-unit-top-list-score')"
    >
      <a-button type="primary" icon="plus" @click="handleAdd"
        >新增榜单信息</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :data-source="teamList"
      :loading="loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="是否确认删除该信息？"
          ok-text="是"
          cancel-text="否"
          @confirm="handleDelete(record)"
        >
          <a-icon
            slot="icon"
            type="close-circle"
            theme="filled"
            style="color: red"
          />
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
import TopListMessageForm from "./components/TopListMessageForm.vue";
import {
  getTeamGradeUnitTopListScore,
  deleteTeamGradeUnitTopListScore,
} from "@/api/evaluation";
export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      teamList: [],
      loading: false,
      columns: [
        {
          title: "榜单类型",
          dataIndex: "type",
        },
        {
          title: "榜单年份",
          dataIndex: "years",
          align: "center",
        },
        {
          title: "分数",
          dataIndex: "score",
          align: "center",
        },
      ],
    };
  },
  created() {
    if (
      this.hasAction("edit-team-evaluation-unit-top-list-score") &&
      this.columns.length < 4
    ) {
      this.columns.push({
        title: "管理",
        scopedSlots: {
          customRender: "action",
        },
        align: "center",
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      getTeamGradeUnitTopListScore({ entityId: this.$route.query.id }).then(
        (res) => {
          this.teamList = res.data;
        }
      );
    },
    handleAdd() {
      this.$dialog(
        TopListMessageForm,
        {
          entityId: parseInt(this.$route.query.id),
          isAdd: true,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "新增榜单信息",
          width: 600,
          centered: true,
          maskClosable: false,
          okText: "保存",
        }
      );
    },
    handleEdit(record) {
      this.$dialog(
        TopListMessageForm,
        {
          record,
          entityId: parseInt(this.$route.query.id),
          isAdd: false,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "编辑榜单信息",
          width: 600,
          centered: true,
          maskClosable: false,
          okText: "保存",
        }
      );
    },
    handleDelete(record) {
      deleteTeamGradeUnitTopListScore({ teamRankId: record.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功！");
          this.loadData();
        } else {
          this.$message.error("删除失败！");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.unit-list {
  background-color: white;
  padding: 20px;
  .action-area {
    margin-bottom: 10px;
  }
}
</style>