<template>
  <a-date-picker mode="year" v-model="value" placeholder="请选择" format="YYYY" :open="isOpen" @openChange="openChange" @panelChange="panelChange" @change="clear"/>
</template>

<script>
import moment from "moment"
export default {
  name: 'YearPicker',
  components:{
  },
  props:{
    setDefuat:{
      type:Number
    }
  },
  data() {
    return {
      value:  null,
      isOpen: false
    }
  },
  created() {
    this.loadData()
  },
  methods:{
    loadData() {
      this.value = this.setDefuat && moment(new Date(this.setDefuat+''))
      this.$emit('change',this.value)
    },
    openChange(state) {
      this.isOpen = state
    },
    panelChange(value){
      this.value = value
      this.$emit('change',this.value)
      this.isOpen = false
    },
    clear() {
      this.value = null
      this.$emit('change',this.value)
    }
  },
 }
</script>

<style scoped>

</style>