<template>
  <div>
    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="榜单类型">
        <a-input
          placeholder="请输入榜单类型"
          v-decorator="[
            'type',
            { initialValue: (record && record.type) || null },
          ]"
        />
      </a-form-item>
      <a-form-item label="榜单年份">
        <year-picker
          v-decorator="['years']"
          :setDefuat="(record && record.years) || null"
        ></year-picker>
      </a-form-item>
      <a-form-item label="分数">
        <a-input-number
          placeholder="请输入分数"
          style="width:25%"
          :step="0.1"
          v-decorator="[
            'score',
            { initialValue: record && record.score != null ? record.score : null },
          ]"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import YearPicker from "@/components/Selectors/YearPicker";
import { addTeamGradeUnitTopListScore,editTeamGradeUnitTopListScore } from "@/api/evaluation";
export default {
  name: "TopListMessageForm",
  components: {
    YearPicker,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    entityId:{
      type: Number,
      require: true
    },
    isAdd: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((error, values) => {
          console.log(values);
          let formData = {
            entityId:this.entityId,
            ...values
          }
          formData.years = formData.years? parseInt(formData.years.format('YYYY')) : null
          if(this.isAdd) {
            addTeamGradeUnitTopListScore(formData).then((res) => {
              if(res.code == 0) {
                this.$message.success('新增成功！')
                resolve(true)
              }else{
                this.$message.error('新增失败！')
              }
            })
          } else {
            formData.id = this.record.id
            editTeamGradeUnitTopListScore(formData).then((res) => {
              if(res.code == 0) {
                this.$message.success('修改成功！')
                resolve(true)
              }else{
                this.$message.error('修改失败！')
              }
            })
          }
        });
      });
    },
  },
};
</script>

<style scoped>
</style>